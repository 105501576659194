.App {
  text-align: center;
  color: #004080;
  margin: 0 auto;
  padding: 0px;
}

.App-button {
  width: 150px;
  padding: 0px;
  margin: 0;
  font-size: 0.9em;
}

.App-header {
  background-color: white;
  height: 30px;
  padding: 0px;
  color: white;
  font-size: 1.2em;
}

.App-link {
  color: white;
  font-size: 1.1em;
  font-weight: 400;
}

.App-footer {
  background-color: black;
  height: 20px;
  padding: 0px;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  position: absolute;
  font-size: 0.8em;
  font-weight: 400;
}

.App-title {
  font-size: 1.2em;
}

.form-basic{
    /*max-width: 640px;*/
    width: 100%;
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
    background-color:  #ffffff;
    font: bold 12px sans-serif;
    text-align: center;
}

.form-login{
    margin: 0;
    padding: 2px;
    box-sizing: border-box;

    background-color:  #ffffff;

    font: bold 12px sans-serif;
    text-align: center;
}

.form-basic .form-row{
    text-align: left;
    margin: 0 auto;
}

.form-basic .form-title-row{
    text-align: center;
    margin: 0 auto;
}

/* The form title */

.form-basic h1{
    display: inline-block;
    box-sizing: border-box;
    color:  #4c565e;
    font-size: 20px;
    padding: 4px;
    border-bottom: 2px solid #6caee0;
    margin: 0;
}

.form-basic .form-row > label span{
    display: inline-block;
    box-sizing: border-box;
    color: #5F5F5F;
    width: 300px;
    text-align: right;
    padding: 8px;
}

.form-basic input{
    color:  #5f5f5f;
    box-sizing: border-box;
    width: 120px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    padding: 4px;
    border: 1px solid #dbdbdb;
}

.form-basic input[type=radio],
.form-basic input[type=checkbox]{
    box-shadow: none;
    width: auto;
}

.form-basic select{
    background-color: #ffffff;
    color:  #5f5f5f;
    box-sizing: border-box;
    width: 120px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    padding: 4px;
    border: 1px solid #dbdbdb;
}


.form-basic textarea{
    color:  #5f5f5f;
    box-sizing: border-box;
    width: 120px;
    height: 80px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    font: normal 12px sans-serif;
    padding: 4px;
    border: 1px solid #dbdbdb;
    resize: vertical;
}

.form-basic .form-radio-buttons{
    display: inline-block;
}

.form-basic .form-radio-buttons > div{
    margin-top: 5px;
}

.form-basic .form-radio-buttons label span{
    margin-left: 5px;
    color: #5f5f5f;
    font-weight: normal;
}

.form-basic .form-radio-buttons input{
    width: auto;
}

.form-basic button{
    display: block;
    border-radius: 2px;
    background-color:  #ffffff;
    color: #ffffff;
    font-weight: bold;
    padding: 4px;
    border: 0;
    margin: 0px 183px 0;
}

.react-datepicker__navigation--next {
    background: url(/chevron-right.png) no-repeat!important;
    width: 24px!important;
    height: 24px!important;
    border: none!important;
    margin-top: -5px!important;
    left:0px!important;
}

.react-datepicker__navigation--previous {
    background: url(/chevron-left.png) no-repeat!important;
    width: 24px!important;
    height: 24px!important;
    border: none!important;
    margin-top: -5px!important;
    right:0px!important;
    left:unset!important;
}